.drawFloorPlanMenu {
  .ant-dropdown-menu {
    width: 167px;
    border-radius: 0px;
    transform: translate(0px, -3px);
  }
}

.updateBackgroundMenu {
  .ant-dropdown-menu {
    width: 167px;
    border-radius: 0px;
    transform: translate(4px, -4px);
  }
}

.downloadFloorPlanMenu {
  .ant-dropdown-menu {
    width: 167px;
    border-radius: 0px;
    transform: translate(4px, -36px);
  }
}
