.pageContainer {
  padding: 20px 24px;

  .pageContent {
    padding: 34px 29px;
    width: 100%;
    height: calc(100vh - 96px - 48px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
}

.searchContainer {
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 24px;
  display: flex;
  justify-content: end;

  .search {
    width: 264px;
    border-radius: 0px;
  }
}

.modalButtons {
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.tableContainer {
  flex: 1;
  overflow-y: auto;
}
