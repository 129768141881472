.container {
  padding: 5px 0px;
  flex-grow: 0;
  flex-shrink: 0;

  height: 100%;
  display: flex;
  align-items: center;

  .button {
    padding: 0px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      top: 20px;
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.06);
    }

    .text {
      margin-right: 10px;
      flex-shrink: 0;
      font-size: 20px;
      color: #ffffff;
    }

    .rect {
      margin-right: 10px;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      background-color: #ffd869;
    }
  }
}

.popoverContent {
  padding-left: 20px;
  height: 65px;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;

  .button {
    padding: 0px;
    border: none;
    border-radius: 0px;
  }

  .rect {
    flex-shrink: 0;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .conic {
      background: conic-gradient(
        from 180deg at 50% 50%,
        #f00 0deg,
        #f08508 50.625deg,
        #ffd869 101.25deg,
        #20c92f 149.9999964237213deg,
        #69c0ff 198.74999284744263deg,
        #165cf6 249.37499284744263deg,
        #9747ff 298.125deg,
        #fff 348.75deg,
        rgba(255, 0, 0, 0.58) 358.1250071525574deg
      );
    }
  }
}
