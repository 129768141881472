.customDrawer {
  .ant-drawer-body {
    padding: 0px;
  }

  .ant-drawer-header-title {
    justify-content: space-between;

    .ant-drawer-title,
    .ant-drawer-close {
      flex-grow: 0;
      flex-shrink: 0;
      flex: none;
    }

    .ant-drawer-title {
      font-size: 16px;
      font-weight: 500;
    }

    .ant-drawer-close {
      order: 1;
      margin: 0px;
    }
  }
}
