.titleContainer {
  margin-bottom: 36px;
  padding: 15px 0px;
  border-bottom: 1px solid #979797;

  .title {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto';
    text-align: center;
  }
}

.contentContainer {
  padding: 0px 74px 30px;
}

.footerContainer {
  padding-bottom: 30px;

  .buttons {
    display: flex;
    justify-content: center;
  }

  .okButton {
    margin-left: 40px;
  }
}
