.container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.checkboxContainer {
  padding-right: 8px;
}

.name {
  font-family: 'Roboto';
}
