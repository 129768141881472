.container {
  margin-bottom: 10px;
  padding-left: 16px;
  height: 40px;
  display: flex;
  align-items: center;

  &.positioned {
    background-color: #fae6e6;
  }

  &.active {
    background-color: #e6f7ff;
  }
}

.label {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttonContainer {
  margin-left: 10px;
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;

  .button {
    margin: 0px;
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}
