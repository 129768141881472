.container {
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .group {
    display: flex;
    align-items: center;
  }

  .fileIconContainer {
    padding-right: 8px;

    .icon {
      color: #ff4d4f;
    }
  }

  .fileName {
    color: #ff4d4f;
  }

  .removeButton {
    .icon {
      transform: translateY(2px);
    }
  }
}
