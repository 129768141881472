.container {
  padding: 24px 24px;
  height: 100%;
  overflow: scroll;
  position: relative;
}

.select {
  width: 100%;
}

.section {
  .sectionLabel .text {
    font-weight: 600;
    font-family: 'Roboto';
  }

  .items {
    padding-left: 15px;
  }

  .commonMarkGroup {
    display: flex;
    flex-direction: column;
  }

  .inputContainer {
    padding-bottom: 20px;

    &:nth-of-type(3) {
      padding-bottom: 30px;
    }

    .inputLabel {
      padding-bottom: 15px;
      font-size: 14px;
      font-family: 'Roboto';
    }

    .fontSizeSelect {
      padding-bottom: 15px;
    }
  }
}

.otherForm {
  padding-bottom: 15px;

  .label {
    padding-bottom: 15px;
  }

  .boothNumberFontSize {
    padding-bottom: 14px;
  }
}

.confirm {
  width: 100%;
  position: sticky;
  left: 24px;
  bottom: 0px;

  .button {
    width: 100%;
  }
}
