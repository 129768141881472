.pageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  .mainIcon {
    width: 150px;
  }
  .title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }
  .form {
    width: 100%;
    max-width: 360px;
    .input {
      max-width: 360px;
      border-radius: 2px;
      border-color: #D9D9D9;
      border-width: 1px;
      height: 40px;
    }
    .icon {
      color: #D94E56;
    }
    .submitBtn {
      width: 100%;
      height: 40px;
      border-radius: 2px;
    }
  }
}