.drawFloorPlanPageOtherDrawer {
  .ant-radio-wrapper {
    padding-bottom: 15px;
  }

  .itemRadio .ant-radio::after,
  .itemRadio .ant-radio-inner,
  .itemRadio .ant-radio-inner::after {
    border-radius: 0px;
  }
}
