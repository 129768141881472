.container {
  padding: 10px 24px 24px;
  position: relative;
}

.form {
  .label {
    padding-bottom: 17px;
    font-size: 16px;
    font-weight: 700;
    color: #b9282c;
  }
}

.boothNumberSize {
  padding-bottom: 14px;
}

.divider {
  margin: 15px 0px;
}

.saveButtonContainer {
  padding: 10px 0px;
  width: 100%;
  position: sticky;
  left: 24px;
  bottom: 24px;

  .saveButton {
    width: 100%;
  }
}
