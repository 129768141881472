.button {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  height: fit-content;
}

.icon {
  display: flex;
  align-items: center;
}

.label {
  padding-left: 13px;
  font-size: 20px;
  color: #ffffff;

  &.disabled {
    color: rgba(175, 190, 213, 1);
  }
}
