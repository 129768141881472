.container {
  display: flex;
  align-items: center;
}

.label {
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 30px;
}

.form {
  flex-shrink: 1;
  flex-grow: 1;

  .select {
    width: 100%;
  }
}
