.searchInput > .ant-input-wrapper > .ant-input-affix-wrapper:not(:last-child) {
  border-start-start-radius:  2px;
  border-end-start-radius:  2px;
}

.searchInput > .ant-input-wrapper > .ant-input-group-addon:last-child > .ant-input-search-button {
  border-start-end-radius:  2px;
  border-end-end-radius:  2px;
}

.select > .ant-select-selector {
  border-radius: 4px;
}

.ant-menu-inline-collapsed {
  width: 60px !important;
}