.pageContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .sider {
    background-color: #fff;

    .innerItem {
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;

      .menuHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        height: 56px;
        min-width: 60px;
        width: 240px;

        &.center {
          justify-content: center;
          padding-right: 0px;
          width: 60px;
        }

        .logo {
          width: 100px;
          padding-left: 16px;

          &.hide {
            display: none;
          }
        }
      }

      .icon {
        font-size: 16px;
        color: #D94E56;
      }

      .footer {
        min-width: 60px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .accountInfo {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 8px;
          padding-left: 16px;
          width: 240px;

          transition: width 0.2s linear;

          &.center {
            width: 60px;
            justify-content: center;
            padding-left: 0px;

            cursor: pointer;
          }

          .userIcon {
            width: 20px;
            height: 20px;
          }

          .userName {
            font-weight: 500;
          }
        }

        .logoutBtn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 8px;
          padding-left: 16px;
          width: 240px;

          transition: width 0.2s linear;

          cursor: pointer;

          transition: opacity .2s linear;

          &:hover {
            opacity: 0.6;
          }

          &.center {
            width: 60px;
            justify-content: center;
            padding-left: 0px;
          }

          .logoutIcon {
            width: 20px;
            height: 20px;
          }

          .logoutText {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #FF666E;
          }
        }
      }
    }

  }

  .content {
    background-color: #F0F2F5;
  }
}

.modalContentContainer {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px ;
  .description {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    .btn {
      padding: 5px 40px;
      height: unset;
    }
  }
}