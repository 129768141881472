.drawerContainer {
  .drawerTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.85);
  }

  .formContainer {
    margin: 8px 0px;
    .rowContainer {
      display: flex;
      margin-bottom: 32px;
      .rowTitle {
        white-space: nowrap;
        font-size: 14px;
        line-height: 32px;
        .red {
          color: #ff0000;
          padding-left: 2px;
        }
      }
      .radio {
        padding-top: 5px;
      }
      .select {
        width: 100%;
      }
      .picker {
        width: 100%;
      }
      .isSentContent {
        font-size: 14px;
        line-height: 32px;
        white-space: pre-wrap;
        &.textArea {
          line-height: 22px;
          padding-top: 5px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    .btn {
      border-radius: 2px;
    }
  }
}