.container {
}

.title {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Roboto';
}

.label {
  padding-bottom: 10px;
}

.selectContainer {
  padding-bottom: 10px;

  .select {
    width: 100%;
  }
}

.inputContainer {
  padding-bottom: 20px;
}
