.container {
  padding: 5px 0px;
  height: 100%;
  display: flex;
  align-items: center;

  .button {
    padding: 0px;
    height: 100%;
    border: none;

    &.active {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .content {
    display: flex;
    align-items: center;
  }

  .label {
    padding-right: 10px;
    font-size: 20px;
    color: #ffffff;
  }
}
