.other-drawer-custom-collapse {
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    display: none !important;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
