.createBoothPopover {
  top: 154px !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
  }
}
