.titleContainer {
  padding: 12px 0px;

  .title {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Roboto';
    text-align: center;
  }
}

.footer {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-around;
}
