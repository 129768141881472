.input {
  transform: translate(-7px, 1px);
}

.tags {
  margin-bottom: -8px;

  .tag {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.select {
  width: 100%;
  transform: translate(-4px, -3px);
}

.operateContainer {
  display: flex;
  align-items: center;

  .button {
    padding: 4px 0px;
  }

  .divider {
    margin: 0px 20px;
    height: 22px;
    background-color: #d0d5dd;
    position: static;
  }
}
