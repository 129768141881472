.header {
  background-color: #fff;
  padding: 16px 24px;
  height: unset;
  line-height: 1.5;

  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  .titleContainer {
    margin-top: 14px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.4;
    }
  }
}