.container {
  height: 100vh;
}

.toolbar {
  padding: 0px 20px;
  background-color: #1890ff;
  overflow-x: auto;
  overflow-y: hidden;

  .toolbarMinWidth {
    min-width: 1160px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mainContent {
  width: 100%;
  height: calc(100vh - 74px - 70px);
  padding: 10px;
  position: relative;

  .floorPlanContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.isCreateBooth {
    cursor: url('../../assets/create_group_mouse.svg') 9 9, auto;
    // cursor: crosshair;
  }
}

.followCompany {
  transform: translate(10px);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  display: none;

  pointer-events: none;
  user-select: none;

  &.show {
    display: block;
  }
}
