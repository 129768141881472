.container {
  flex-grow: 0;
  flex-shrink: 0;
}

.button {
  height: 100%;

  .imageContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .menuImage {
    margin-right: 10px;
  }
}
