.container {
  // min-height: 215px;
}

.item {
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  &:nth-last-child(2) {
    padding-bottom: 170px;
  }

  .label {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100px;
  }

  .tagsLabel {
    height: 24px;
    align-self: flex-start;
  }

  .select {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.confirmContainer {
  display: flex;
  justify-content: center;
}
