.container {
  padding: 24px 12px;
}

.formItem {
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  .label {
    width: 92px;
  }

  .select {
    flex: 1;
    overflow: hidden;
  }
}

.lengthFilter {
  padding-left: 16px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
