.container {
  padding-bottom: 10px;
}

.title {
  padding-bottom: 10px;
  font-weight: 700;
  font-family: 'Roboto';
}

.item {
  padding-bottom: 10px;

  &:nth-last-child(2) {
    padding-bottom: 20px;
  }

  .label {
    padding-bottom: 14px;
    font-family: 'Roboto';
  }

  .form {
    padding-bottom: 14px;
  }

  .select {
    width: 100%;
  }
}

.tags {
  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      flex-shrink: 0;
      font-family: 'Roboto';
    }

    .tagsButton {
      flex-shrink: 0;
      padding: 0px;
      height: fit-content;
    }

    .icon {
      font-size: 30px;
      color: #1890ff;
    }
  }
}
