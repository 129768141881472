.pageContainer {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  .contentContainer {
    height: calc(100% - 46px);
    overflow: auto;
    margin: 20px 24px 26px;
    padding: 30px 48px 0px;

    background: #FFFFFF;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
    border-radius: 2px;

    display: grid;
    grid-template-rows: auto auto 1fr;
    .filterContainer {
      display: flex;
      align-items: center;
      column-gap: 32px;
      margin-bottom: 18px;

      .selectContainer {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .selectTitle {
          font-weight: 400;
          line-height: 1.57;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }

        .select {
          min-width: 160px;
        }
      }

      .addButton {
        margin-left: auto;
        max-width: 264px;
        border-radius: 2px;
      }
    }

    .headerContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #FAFAFA;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      border-radius: 2px;

      .headerColumn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0px;
        font-weight: 700;
      }
      .customTitle {
        display: flex;
        align-items: center;
        column-gap: 4px;
        justify-content: center;

        cursor: pointer;
        .arrowContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .icon {
            color:rgba(0, 0, 0, 0.2)
          }
          .upIcon {
            transform: translateY(2px);
          }
          .downIcon {
            transform: translateY(-2px);
          }
          .active {
            color:rgba(0, 0, 0, 0.8)
          }
        }
      }
    }
    .tableContainer {
      height: 100%;
      overflow: auto;
      .nameColumn {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient :vertical;
        overflow: hidden;
        width: 100%;
      }
    }
  } 
}