.container {
  margin-right: 8px;
  margin-bottom: 8px;
  height: 24px;
}

.input {
  margin-right: 8px;
  width: 100px;
  height: 100%;
  vertical-align: top;
  display: none;
}

.tag {
  margin: 0px;
  height: 100%;
  line-height: 24px;
  background-color: transparent !important;
  border-style: dashed;
  cursor: pointer;
  display: none;
}

.createIcon {
  margin-right: 8px;
}

.show {
  display: block;
}
