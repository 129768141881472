@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Noto Sans TC', 'Roboto', sans-serif;
}

body {
  margin: 0;
  color: #393c3e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;

  font-family: 'Noto Sans TC', 'Roboto', sans-serif;

  overscroll-behavior: none;
}

input,
textarea {
  font-size: initial;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-break: normal;
  /* anywhere */
}

.nowrap {
  word-wrap: nowrap;
  white-space: nowrap;
}

a {
  color: #393c3e;
  transition: opacity 0.2s linear;
}

a:hover {
  color: #393c3e;
  opacity: 0.8;
}

input {
  border: 0px solid #000;
  padding: 0px;
}

input:focus-visible {
  outline: 0px;
}
