.container {
  display: flex;
  align-items: center;

  .label {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
}
