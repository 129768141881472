.booth-drawer-custom-collapse {
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    padding: 0px !important;
    justify-content: space-between;
  }

  .ant-collapse-header-text {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    color: #b9282c;
  }

  .ant-collapse-arrow {
    font-size: 12px !important;
    font-weight: 900 !important;
    color: #b9282c !important;
  }

  .ant-collapse-expand-icon {
    order: 1;
    padding: 0px !important;
  }

  .ant-collapse-content {
    margin-top: 15px;
    border: none;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
