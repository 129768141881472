.container {
  margin-right: 8px;
  margin-bottom: 8px;
  height: 24px;
}

.input {
  margin-right: 8px;
  width: 100px;
  height: 100%;
  vertical-align: top;
  display: none;
}

.tag {
  margin: 0px;
  height: 100%;
  line-height: 24px;
  position: relative;
  cursor: pointer;
  display: none;

  &.disabled {
    opacity: 0.5;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      cursor: not-allowed;
    }
  }
}

.createIcon {
  margin-right: 8px;
}

.show {
  display: block;
}
