.loadingContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  z-index: 10000;

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .curtain {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .iconContainer {
    position: absolute;
  }
}
